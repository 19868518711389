import React from 'react'
import {createTheme, ThemeProvider, adaptV4Theme} from '@mui/material/styles';
import RTL from "../../language/rtl";
import {connect} from 'react-redux'


function Shell(props) {
    const {dir} = props;
    const theme = createTheme(adaptV4Theme({
        direction: dir, typography: {
            th:{
                fontWeight:500,
                fontWeightBold: 500
            },
            useNextVariants: true,
            body: {
                backgroundColor: 'white'
            },
            fontFamily: [
                'Rubik'
            ].join(','),
            fontWeightLight: 200,
            fontWeightRegular: 300,
            fontWeightMedium: 400,
            fontWeightBold: 500,
            button: {
                fontSize: 16,
                fontWeight: 400,
                textTransform: 'none',
            },
        },
        overrides: {
            MuiCssBaseline: {
                '@global': {
                    body: {
                        backgroundColor: "white"
                    },
                    '@font-face': {
                        fontFamily: 'Rubik',
                        fontWeightBold: 500

                    }

                }
            },
            MuiButtonBase: {
                root: {
                    boxShadow: 'none',
                    '&:focus-visible': {
                        boxShadow: '0 0 0 0.15rem rgba(0, 0, 0)',
                    },
                }
            },
            MuiIconButton: {
                root: {
                    '&:hover': {
                        backgroundColor: '#cccccc'
                    },
                }
            },
            MuiTypography: {
                root: {
                    '&.MuiTypography-body1': {
                        //fontSize: '16px'
                    }
                }
            },
        }
    }));

    return (
      <RTL direction={dir}>
          <ThemeProvider theme={theme}>
              {props.children}
          </ThemeProvider>
      </RTL>
    );
}
const mapStateToProps = (state, ownProps) => {
    return {
        dir: state.otp.config.dir
    }
}
const mapDispatchToProps = {
}
export default connect(mapStateToProps, mapDispatchToProps)(Shell)