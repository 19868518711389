// import this polyfill in order to make webapp compatible with IE 11
import 'es6-math'

import "./index.css";
import React, {Suspense, lazy} from 'react';
import {createBrowserHistory} from 'history'
import {
    createRouterMiddleware,
    createRouterReducer,
} from '@lagunovsky/redux-react-router'
import {createStore, combineReducers, applyMiddleware, compose} from 'redux'
import {Provider} from 'react-redux'
import thunk from 'redux-thunk'
import CircularProgress from '@mui/material/CircularProgress';
import Shell from './lib/components/app/shell'
import createOtpReducer from './lib/reducers/create-otp-reducer'
import configFile from './lib/config'
import RouteWeb from "./routes";

// load the OTP configuration
const otpConfig = configFile;

const history = createBrowserHistory();
const routerMiddleware = createRouterMiddleware(history);

const middleware = [
  thunk,
  routerMiddleware
];

const rootReducer = combineReducers({router: createRouterReducer(history), otp: createOtpReducer(otpConfig)});
const store = createStore(rootReducer, compose(applyMiddleware(...middleware)));

// render the app
function App(props) {
    return <Provider store={store}>
        <Suspense
          fallback={<div style={{position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
              <CircularProgress sx={{alignItems: 'center', alignSelf: 'center'}}/></div>}>
            <Shell>
              <RouteWeb/>
            </Shell>
        </Suspense>
    </Provider>
}
export default App

