export function configuredDirectionByLanguage(type) {
  let G_isRtl = document.body.getAttribute("dir") === "rtl";
    let isRTL = type === "he" || type === "ar"
    if (isRTL) {
        if (!G_isRtl) {
            document.body.setAttribute("dir", "rtl")
        }
    } else {
        if (G_isRtl) {
            document.body.setAttribute("dir", "ltr")
        }
    }
}