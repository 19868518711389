import i18n from "../../../../i18nSetup";
import {getItem} from '../../../@opentripplanner/core-utils/src/storage'





/**
 * Create customized geocoder functions given a certain geocoding API, the
 * config for the geocoder and response rewrite functions specific to this
 * application. Any geocoder api that is added is expected to have an API that
 * behaves very closely to https://github.com/conveyal/isomorphic-mapzen-search
 */
export default class Geocoder {
  constructor(geocoderApi, geocoderConfig) {
    this.api = geocoderApi;
    this.geocoderConfig = geocoderConfig;
  }

  /**
   * Perform an autocomplete query. Eg, using partial text of a possible
   * address or POI, attempt to find possible matches.
   */
  autocomplete(query) {

    

    let autoComp = this.getAutocompleteQuery(query);
     if(!autoComp.text || autoComp.text.length < 2)
        return Promise.resolve({
          // remove any autocomplete results that are collections
          // (eg multiple Starbucks)
          features:[]})

    let url = this.geocoderConfig.bnbBaseUrl + "/geocode?locale=" + (getItem('userLanguage') || "he") + "&query=" + autoComp.text;
    
    // autoComp.url + "?format=json&countrycodes=IL&addressdetails=1&namedetails=1&extratags=1&q=" + autoComp.text;
    return fetch(url).then(result => result.json(),err => {
      console.log(err)
    }).then(this.rewriteAutocompleteResponse, err2 => {
      console.log(err2);
    });
   /* return this.api
      .autocomplete(this.getAutocompleteQuery(query))
      .then(this.rewriteAutocompleteResponse);*/
  }

  /**
   * Get an application-specific data structure from a given feature. The
   * feature is either the result of an autocomplete or a search query. This
   * function returns a Promise because sometimes an asynchronous action
   * needs to be taken to translate a feature into a location. For example,
   * the ArcGIS autocomplete service returns results that lack full address
   * data and GPS and it is expected that an extra call to the `search` API is
   * done to obtain that detailed data.
   */
  getLocationFromGeocodedFeature(feature) {
    const location = {lon: feature.lng, lat: feature.lat}//  lonlat.fromCoordinates(feature.geometry.coordinates);
    location.name = feature.properties.label;
    location.rawGeocodedFeature = feature;
    return Promise.resolve(location);
  }

  /**
   * Do a reverse-geocode. ie get address information and attributes given a
   * GPS coordiante.
   */
  reverse(query) {
    return fetch(this.getReverseQuery(query),{
      headers:{'accept-language':i18n.language}
    })
      .then(result => result.json()).then(this.rewriteReverseResponse);
  }

  /**
   * Perform a search query. A search query is different from autocomplete in
   * that it is assumed that the text provided is more or less a complete
   * well-fromatted address.
   */
  search(query) {

    //console.log(query);
    return null;
   /* return this.api
      .search(this.getSearchQuery(query))
      .then(this.rewriteSearchResponse);*/
  }

  /**
   * Default autocomplete query generator
   */
  getAutocompleteQuery(query) {
    const { apiKey, baseUrl, boundary, focusPoint } = this.geocoderConfig;
    return {
      apiKey,
      boundary,
      focusPoint,
      url: baseUrl ? `${baseUrl}/search` : undefined,
      ...query
    };
  }

  /**
   * Default reverse query generator
   */
  getReverseQuery(query) {

    const { baseUrl } = this.geocoderConfig;
    let q = "/reverse?format=json&addressdetails=1&extratags=1&namedetails=1&lat=" + query.point.lat + "&lon=" + query.point.lon;
    return baseUrl + q;
  
    /*return {
      apiKey,
      format: true,
      url: baseUrl ? `${baseUrl}/reverse` : undefined,
      ...query
    };*/
  }

  /**
   * Default search query generator.
   */
  getSearchQuery(query) {
    const { apiKey, baseUrl, boundary, focusPoint } = this.geocoderConfig;
    return {
      apiKey,
      boundary,
      focusPoint,
      url: baseUrl ? `${baseUrl}/search` : undefined,
      format: false, // keep as returned GeoJSON,
      ...query
    };
  }

  /**
   * Default rewriter for autocomplete responses
   */
  rewriteAutocompleteResponse(response) { 
    return {
      // remove any autocomplete results that are collections
      // (eg multiple Starbucks)
      features: response
     //   .filter(feature => !feature.isCollection)
        // add label property so location-field can handle things ok
        .map(feature => {

          let label = feature.description ? (feature.description+ " ") : "";
          if(feature.formatted_address)
              label +=  feature.formatted_address;
          if(feature.description && feature.description.includes(feature.formatted_address)){
            label = feature.description;
          }else if(feature.formatted_address && feature.formatted_address.includes(feature.description)){
            label = feature.formatted_address;
          }
          return {
          ...feature,
          properties: {
            label
            
          }
        }
        })
    };
  }

  /**
   * Default rewriter for reverse responses
   */
  rewriteReverseResponse(response) {
   
    if(response.error)
        return null;
   // const { lat, lon } = response;
    let lat = parseFloat(response.lat);
    let lon = parseFloat(response.lon);
   /* if(response.namedetails){
      for(let key in response.namedetails){
        console.log("got namee details:" + key);
      }
    }*/
    let name = response.display_name;
    if(response.namedetails && response.namedetails.name){
      name = response.namedetails.name;
    }
    else if(response.address){
      let result = [];
      let address = "";
      if(response.address.road)
          address += response.address.road;
      if(response.address.house_number)
          address += " " + response.address.house_number;
      if(address){
        result.push(address.trim())
      }
      if(response.address.town)
        result.push(response.address.town)
      if(response.address.city)
          result.push(response.address.city)
      if(result.length){
        name = result.join(", ").trim();
      }else{
        console.log("empty address:" + result);
      }
        
      
    }
    
    return {
      lat,
      lon,
      name,
      rawGeocodedFeature: response
    };
  }

  /**
   * Default rewriter for search responses
   */
  rewriteSearchResponse(response) {
    return response;
  }
}