/* globals fetch */
import { push, replace } from '@lagunovsky/redux-react-router'
import hash from 'object-hash'
import { createAction } from 'redux-actions'
import qs from 'qs'
import moment from 'moment'
import haversine from 'haversine'

import { rememberPlace } from './map'
import { hasCar } from '../@opentripplanner/core-utils/src/itinerary'
import { getTripOptionsFromQuery, getUrlParams } from '../@opentripplanner/core-utils/src/query'
import queryParams from '../@opentripplanner/core-utils/src/query-params'
import { getStopViewerConfig, queryIsValid } from '../@opentripplanner/core-utils/src/state'
import { randId } from '../@opentripplanner/core-utils/src/storage'
import { OTP_API_DATE_FORMAT, OTP_API_TIME_FORMAT } from '../@opentripplanner/core-utils/src/time'
import {getItem} from '../@opentripplanner/core-utils/src/storage'
import {setViewedRoute} from './ui'

if (typeof (fetch) === 'undefined') require('isomorphic-fetch')

// Generic API actions

export const nonRealtimeRoutingResponse = createAction('NON_REALTIME_ROUTING_RESPONSE')
export const routingRequest = createAction('ROUTING_REQUEST')
export const routingResponse = createAction('ROUTING_RESPONSE')
export const routingError = createAction('ROUTING_ERROR')
export const toggleTracking = createAction('TOGGLE_TRACKING')
export const rememberSearch = createAction('REMEMBER_SEARCH')
export const forgetSearch = createAction('FORGET_SEARCH')
export const pricesRequest = createAction('PRICES_REQUEST')
export const pricesResponse = createAction('PRICES_RESPONSE')
export const pricesError = createAction('PRICES_ERROR')
export const setNeedStopInformation = createAction('SET_NEED_STOP_INFORMATION')

function formatRecentPlace (place) {
  return {
    ...place,
    type: 'recent',
    icon: 'clock-o',
    id: `recent-${randId()}`,
    timestamp: new Date().getTime()
  }
}

function formatRecentSearch (url, otpState) {
  return {
    query: getTripOptionsFromQuery(otpState.currentQuery, true),
    url,
    id: randId(),
    timestamp: new Date().getTime()
  }
}

function isStoredPlace (place) {
  return ['home', 'work', 'suggested', 'stop'].indexOf(place.type) !== -1
}


/**
 * Compute the initial activeItinerary. If this is the first search of
 * session (i.e. searches lookup is empty/null) AND an activeItinerary ID
 * is specified in URL parameters, use that ID. Otherwise, use null/0.
 */
function getActiveItinerary (otpState) {
  let activeItinerary = otpState.currentQuery.routingType === 'ITINERARY' ? 0 : null
  // We cannot use window.history.state here to check for the active
  // itinerary param because it is unreliable in some states (e.g.,
  // when the print layout component first loads).
  const urlParams = getUrlParams()
  if (
    (!otpState.searches || Object.keys(otpState.searches).length === 0) &&
    urlParams.ui_activeItinerary
  ) {
    activeItinerary = +urlParams.ui_activeItinerary
  }
  return activeItinerary
}

/**
 * Send a routing query to the OTP backend.
 *
 * NOTE: We need a random ID so that when a user reloads the page (clearing the
 * state), performs searches, and presses back to load previous searches
 * that are no longer contained in the state we don't confuse the search IDs
 * with search IDs from the new session. If we were to use sequential numbers
 * as IDs, we would run into this problem.
 */
export function routingQuery (searchId = null) {
  return async function (dispatch, getState) {
    const otpState = getState().otp
    const isNewSearch = !searchId
    if (isNewSearch) searchId = randId()
    const routingType = otpState.currentQuery.routingType
    // Don't permit a routing query if the query is invalid
    if (!queryIsValid(otpState)) {
      console.warn('Query is invalid. Aborting routing query', otpState.currentQuery)
      return
    }
    const activeItinerary = getActiveItinerary(otpState)
    dispatch(routingRequest({ activeItinerary, routingType, searchId }))

    // fetch a realtime route
    const query = constructRoutingQuery(otpState)
    fetch(query)
      .then(getJsonAndCheckResponse)
      .then(json => {

        if(json && json.plan && json.plan.itineraries && json.plan.itineraries.length){
            itinerariesReducer(json.plan.itineraries);
            json.plan.boardingStops = [];
            if(otpState.config.needStopInformation){
            json.plan.itineraries.forEach(itinerary => {
              itinerary.legs.forEach(leg => {
                if(leg.transitLeg && !json.plan.boardingStops.includes(leg.from.stopId)){
                  json.plan.boardingStops.push(leg.from.stopId)
                  dispatch(findStop({stopId:leg.from.stopId}))
                }
              })
            })
            }
        }

        dispatch(routingResponse({ response: json, searchId }))
        // If tracking is enabled, store locations and search after successful
        // search is completed.
        if (otpState.user.trackRecent) {
          const { from, to } = otpState.currentQuery
          if (!isStoredPlace(from)) {
            dispatch(rememberPlace({ type: 'recent', location: formatRecentPlace(from) }))
          }
          if (!isStoredPlace(to)) {
            dispatch(rememberPlace({ type: 'recent', location: formatRecentPlace(to) }))
          }
          dispatch(rememberSearch(formatRecentSearch(query, otpState)))
        }
      })
      .catch(error => {
        dispatch(routingError({ error, searchId }))
      })
    // Update OTP URL params if a new search. In other words, if we're
    // performing a search based on query params taken from the URL after a back
    // button press, we don't need to update the OTP URL.
    // TODO: For old searches that we are re-running, should we be **replacing**
    //  the URL params here (instead of **pushing** a new path to history like
    //  what currently happens in updateOtpUrlParams)? That way we could ensure
    //  that the path absolutely accurately reflects the app state.
    const params = getUrlParams()
    if (isNewSearch || params.ui_activeSearch !== searchId) {
      dispatch(updateOtpUrlParams(otpState, searchId))
    }
    // also fetch a non-realtime route
  /*  fetch(constructRoutingQuery(otpState, true))
      .then(getJsonAndCheckResponse)
      .then(json => {
        // FIXME: This is only performed when ignoring realtimeupdates currently, just
        // to ensure it is not repeated twice.
        dispatch(nonRealtimeRoutingResponse({ response: json, searchId }))
      })
      .catch(error => {
        console.error(error)
        // do nothing
      })*/
  }
}

function itinerariesReducer(itineraries){

  let tripsToDelete = [];
  for(let i = 0;i<itineraries.length;i++){
    if(compareRoute(i,itineraries[i],itineraries)){
      tripsToDelete.push(i);
    }
  }
  for(let i = tripsToDelete.length-1;i >= 0;i--)
     itineraries.splice(tripsToDelete[i], 1);

}

function compareRoute(index,route,itineraries){

  let canDelete = false;
  for(let i = 0;i<index;i++){

  //    console.log("comparing route:",index,"with route:",i);
      const testRoute = itineraries[i];
      if(testRoute.canDelete) // don't compare to a route already marked for deletion
          continue;
      if(testRoute.legs.length !== route.legs.length)
          continue;

      let sameSteps = true;
      for(var z = 0;z<testRoute.legs.length;z++){
        const routeStep = route.legs[z];
        const testStep = testRoute.legs[z];
        if(routeStep.mode !== testStep.mode){
          sameSteps = false;
          break;
        }

        if(routeStep.transitLeg){

          if((routeStep.agencyId !== testStep.agencyId) && (routeStep.agencyId >= 100 || testStep.agencyId >= 100)){
            sameSteps = false;
            break;
          }

          if(routeStep.to.stopCode !== testStep.to.stopCode){
            sameSteps = false;
            break;
          }

          if(routeStep.from.stopCode !== testStep.from.stopCode){
              sameSteps = false;
              break;
          }

          if(routeStep.intermediateStops.length !== testStep.intermediateStops.length)
          {

            if(!routeStep.routeShortName || (routeStep.routeShortName !== testStep.routeShortName)){ // if same line, but diffrent num of stops (line alternative) still concider same route..
            //  console.log("same stops but different number of stops:",testStep.transit_details.num_stops,routeStep.transit_details.num_stops);
              sameSteps = false;
              break;
            }
          }

          if(!routeStep.routeShortName){

              if(!routeStep.headsign){              
                  this.createHeadsignForStep(routeStep);                     
              }

              if(routeStep.headsign !== testStep.headsign)
              {
                sameSteps = false;
                break;
              }
          }
          else if((routeStep.routeShortName !== testStep.routeShortName) || (routeStep.agencyId !== testStep.agencyId))
          {

            if(testStep.shortNames && (!testStep.shortNames.includes(routeStep.routeShortName) || !testStep.agencyIds.includes(routeStep.agencyId))){

            
                let index = 0;
                for(index = 0;index<testStep.shortNames.length;index++){
                      if(parseInt(testStep.shortNames[index]) > parseInt(routeStep.routeShortName))
                          break;
                }
                testStep.shortNames.splice(index,0,routeStep.routeShortName);
                testStep.agencyIds.splice(index,0,routeStep.agencyId);
                testStep.headsigns.splice(index,0,routeStep.headsign);
                testStep.routeColors.splice(index,0,routeStep.routeColor);
          
            
          //  console.log("same stops but different route:",routeStep.transit_details.line.short_name,testStep.transit_details.line.short_name);
           // const line = routeStep.transit_details.line;
           // const testLine = testStep.transit_details.line;
           // const headsign = routeStep.transit_details.headsign;
            //this.decodeMetaForLine(line,testLine,headsign);
          }
          else {
        //    console.log("already had meta line ",routeStep.transit_details.line.short_name);
          }
          }

          const routeId = parseInt(routeStep.routeId.split(':')[1]);
          if(sameSteps && testStep.routeIds && !testStep.routeIds.includes(routeId)){
            testRoute.uniqueKey += (routeStep.routeShortName || routeId);
            testStep.routeIds.push(routeId);
            //this.decodeMetaForLine(routeId,testStep);
          }
        }
      }

      if(sameSteps){
        route.canDelete = true;
        canDelete = true;
        break;
      }
  }

  return canDelete;
}



export function pricesQuery (activeItinerary,searchId = null) {
  return async function (dispatch, getState) {
    const otpState = getState().otp
    const isNewSearch = !searchId
    if (isNewSearch) searchId = randId()
    const routingType = otpState.currentQuery.routingType
    // Don't permit a routing query if the query is invalid
   
    //const activeItinerary = getActiveItinerary(otpState)
   // dispatch(routingRequest({ activeItinerary, routingType, searchId }))
   dispatch(pricesRequest({ activeItinerary, routingType, searchId }))
    // fetch a realtime route
    const data = compactItinerary(activeItinerary)
    const endpoint = "https://busnearby.co.il/pricecalc";
    fetch(endpoint, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      //mode: 'cors', // no-cors, *cors, same-origin
   //   cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
     // credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
   //   redirect: 'follow', // manual, *follow, error
   //   referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    })
      .then(getJsonAndCheckResponse)
      .then(json => {
        dispatch(pricesResponse({ response: json}))
    
      })
      .catch(error => {
        dispatch(pricesError({ error }))
      })
    }
}


function getJsonAndCheckResponse (res) {
  if (res.status >= 400) {
    const error = new Error('Received error from server')
    error.response = res
    throw error
  }
  return res.json()
}




function compactItinerary(data){
    let result = {legs:[],lang:'he',os:'browser'};
    data.legs.forEach(leg => {
      if(!leg.transitLeg)
          return;
      let compactLeg = {};
      compactLeg.startTime = leg.startTime;
      compactLeg.endTime = leg.endTime;
      compactLeg.fromStopCode = leg.from.stopCode;
      compactLeg.toStopCode = leg.to.stopCode;
      compactLeg.fromZoneId = leg.from.zoneId;
      compactLeg.toZoneId = leg.to.zoneId;
      compactLeg.routeId = leg.routeId;
      compactLeg.route =  isNaN(leg.route) ? "" : leg.route; //- don't send, can contain heb unicide which is unsupported in encoder
      compactLeg.agencyId = leg.agencyId;
      result.legs.push(compactLeg);

    })
    return result;
}

function constructRoutingQuery (otpState, ignoreRealtimeUpdates) {
  const { config, currentQuery } = otpState
  const routingType = currentQuery.routingType
  // Check for routingType-specific API config; if none, use default API
  const rt = config.routingTypes && config.routingTypes.find(rt => rt.key === routingType)
  const api = (rt && rt.api) || config.api
  const planEndpoint = `${api.host}${api.port
    ? ':' + api.port
    : ''}${api.path}${api.plan ? api.plan : ''}`
  const params = getRoutingParams(otpState, ignoreRealtimeUpdates)
  return `${planEndpoint}?${qs.stringify(params)}`
}

function getRoutingParams (otpState, ignoreRealtimeUpdates) {
  const { config, currentQuery } = otpState
  const routingType = currentQuery.routingType
  const isItinerary = routingType === 'ITINERARY'
  let params = {}

  // Start with the universe of OTP parameters defined in query-params.js:
  queryParams
    .filter(qp => {
      // A given parameter is included in the request if all of the following:
      // 1. Must apply to the active routing type (ITINERARY or PROFILE)
      // 2. Must be included in the current user-defined query
      // 3. Must pass the parameter's applicability test, if one is specified
      return qp.routingTypes.indexOf(routingType) !== -1 &&
        qp.name in currentQuery &&
        (typeof qp.applicable !== 'function' || qp.applicable(currentQuery, config))
    })
    .forEach(qp => {
      // Translate the applicable parameters according to their rewrite
      // functions (if provided)
      const rewriteFunction = isItinerary
        ? qp.itineraryRewrite
        : qp.profileRewrite
      params = Object.assign(
        params,
        rewriteFunction
          ? rewriteFunction(currentQuery[qp.name])
          : { [qp.name]: currentQuery[qp.name] }
      )
    })

  // Additional processing specific to ITINERARY mode
  if (isItinerary) {
    // override ignoreRealtimeUpdates if provided
    if (typeof ignoreRealtimeUpdates === 'boolean') {
      params.ignoreRealtimeUpdates = ignoreRealtimeUpdates
    }

    // check date/time validity; ignore both if either is invalid
    const dateValid = moment(params.date, OTP_API_DATE_FORMAT).isValid()
    const timeValid = moment(params.time, OTP_API_TIME_FORMAT).isValid()

    if (!dateValid || !timeValid) {
      delete params.time
      delete params.date
    }

    // temp: set additional parameters for CAR_HAIL or CAR_RENT trips
    if (
      params.mode &&
      (params.mode.includes('CAR_HAIL') || params.mode.includes('CAR_RENT'))
    ) {
      params.minTransitDistance = '50%'
      // increase search timeout because these queries can take a while
      params.searchTimeout = 10000
    }

    // set onlyTransitTrips for car rental searches
    if (params.mode && params.mode.includes('CAR_RENT')) {
      params.onlyTransitTrips = true
    }

  // Additional processing specific to PROFILE mode
  } else {
    // check start and end time validity; ignore both if either is invalid
    const startTimeValid = moment(params.startTime, OTP_API_TIME_FORMAT).isValid()
    const endTimeValid = moment(params.endTime, OTP_API_TIME_FORMAT).isValid()

    if (!startTimeValid || !endTimeValid) {
      delete params.startTimeValid
      delete params.endTimeValid
    }
  }

  // TODO: check that valid from/to locations are provided

  // hack to add walking to driving/TNC trips
  /*if (hasCar(params.mode)) {
    params.mode += ',WALK'
  }*/

  return params
}

// Park and Ride location query

export const parkAndRideError = createAction('PARK_AND_RIDE_ERROR')
export const parkAndRideResponse = createAction('PARK_AND_RIDE_RESPONSE')

export function parkAndRideQuery (params) {
  let endpoint = 'park_and_ride'
  if (params && Object.keys(params).length > 0) {
    endpoint += '?' + Object.keys(params).map(key => key + '=' + params[key]).join('&')
  }
  return createQueryAction(endpoint, parkAndRideResponse, parkAndRideError)
}

// bike rental station query

export const bikeRentalError = createAction('BIKE_RENTAL_ERROR')
export const bikeRentalResponse = createAction('BIKE_RENTAL_RESPONSE')

export function bikeRentalQuery (params) {
  return createQueryAction('bike_rental', bikeRentalResponse, bikeRentalError)
}

// Car rental (e.g. car2go) locations lookup query

export const carRentalResponse = createAction('CAR_RENTAL_RESPONSE')
export const carRentalError = createAction('CAR_RENTAL_ERROR')

export function carRentalQuery (params) {
  return createQueryAction('car_rental', carRentalResponse, carRentalError)
}

// Vehicle rental locations lookup query. For now, there are 3 seperate
// "vehicle" rental endpoints - 1 for cars, 1 for bicycle rentals and another
// for micromobility. In the future, the hope is to consolidate these 3
// endpoints into one.

export const vehicleRentalResponse = createAction('VEHICLE_RENTAL_RESPONSE')
export const vehicleRentalError = createAction('VEHICLE_RENTAL_ERROR')

export function vehicleRentalQuery (params) {
  return createQueryAction('vehicle_rental', vehicleRentalResponse, vehicleRentalError)
}

const findScheduleRouteForStopResponse = createAction('FIND_SCHEDULES_ROUTE_FOR_STOPS_RESPONSE');
const findScheduleRouteForStopError = createAction('FIND_SCHEDULES_ROUTE_FOR_STOPS_ERROR');

export function findScheduleRouteForStop (params, queryParams) {
  return createQueryAction(
    `index/stops/${params.stopId}/stoptimes/${params.travelDate}?${new URLSearchParams(queryParams)}`,
    findScheduleRouteForStopResponse,
    findScheduleRouteForStopError,
    {
      serviceId: 'stops',
      postprocess: (payload, dispatch) => {
      },
      noThrottle: true
    }
  )
}

const findRouteDirectionsResponse = createAction('FIND_ROUTE_DIRECTIONS_RESPONSE');
const findRouteDirectionsError = createAction('FIND_ROUTE_DIRECTIONS_ERROR');

export function findRouteDirections (groupId, queryParams) {
  return createQueryAction(
    `index/patterns/directions/${groupId}?${new URLSearchParams(queryParams)}`,
    findRouteDirectionsResponse,
    findRouteDirectionsError,
    {
      serviceId: 'stops',
      postprocess: (payload, dispatch) => {
        payload && payload.length && payload.sort((a,b) => {
            if(null != a.motDirection && null != b.motDirection){
            let result = a.motDirection - b.motDirection;
            if(!result && null != a.alternative && null != b.alternative){
              if(a.alternative == b.alternative){
                return 0;
              }else if(a.alternative == '#'){
                return -1;
              }else if(b.alternative == '#'){
                return 1;
              }

              return a.alternative - b.alternative;
            }
          }
          return 0;
        })
      },
      noThrottle: true
    }
  )
}

// Single stop lookup query
const findStopResponse = createAction('FIND_STOP_RESPONSE')
const findStopError = createAction('FIND_STOP_ERROR')

export function findStop (params) {

  const queryParams = "?locale=" + (getItem('userLanguage') || "he");
  return createQueryAction(
    `index/stops/${params.stopId}${queryParams}`,
    findStopResponse,
    findStopError,
    {
      serviceId: 'stops',
      postprocess: (payload, dispatch) => {
        dispatch(findRoutesAtStop(params.stopId))
        dispatch(findStopTimesForStop(params))
      },
      noThrottle: true
    }
  )
}

export function findRoutesAndTimesForStop(params){
  return async function (dispatch, getState) {
    dispatch(findRoutesAtStop(params.stopId))
    dispatch(findStopTimesForStop(params))
  }
}

// TODO: Optionally substitute GraphQL queries? Note: this is not currently
// possible because gtfsdb (the alternative transit index used by TriMet) does not
// support GraphQL queries.
// export function findStop (params) {
//   const query = `
// query stopQuery($stopId: [String]) {
//   stops (ids: $stopId) {
//     id: gtfsId
//     code
//     name
//     url
//     lat
//     lon
//     stoptimesForPatterns {
//       pattern {
//         id: semanticHash
//         route {
//           id: gtfsId
//           longName
//           shortName
//           sortOrder
//         }
//       }
//       stoptimes {
//         scheduledArrival
//         realtimeArrival
//         arrivalDelay
//         scheduledDeparture
//         realtimeDeparture
//         departureDelay
//         timepoint
//         realtime
//         realtimeState
//         serviceDay
//         headsign
//       }
//     }
//   }
// }
// `
//   return createGraphQLQueryAction(
//     query,
//     { stopId: params.stopId },
//     findStopResponse,
//     findStopError,
//     {
//       // find stop should not be throttled since it can make quite frequent
//       // updates when fetching stop times for a stop
//       noThrottle: true,
//       serviceId: 'stops',
//       rewritePayload: (payload) => {
//         // convert pattern array to ID-mapped object
//         const patterns = []
//         const { stoptimesForPatterns, ...stop } = payload.data.stops[0]
//         stoptimesForPatterns.forEach(obj => {
//           const { pattern, stoptimes: stopTimes } = obj
//           // It's possible that not all stop times for a pattern will share the
//           // same headsign, but this is probably a minor edge case.
//           const headsign = stopTimes[0]
//             ? stopTimes[0].headsign
//             : pattern.route.longName
//           const patternIndex = patterns.findIndex(p =>
//             p.headsign === headsign && pattern.route.id === p.route.id)
//           if (patternIndex === -1) {
//             patterns.push({ ...pattern, headsign, stopTimes })
//           } else {
//             patterns[patternIndex].stopTimes.push(...stopTimes)
//           }
//         })
//         return {
//           ...stop,
//           patterns
//         }
//       }
//     }
//   )
// }

// Single trip lookup query

export const findTripResponse = createAction('FIND_TRIP_RESPONSE')
export const findTripError = createAction('FIND_TRIP_ERROR')

export function findTrip (params) {
 // return createQueryAction(
  //  `index/trips/${params.tripId}`,
  //  findTripResponse,
  //  findTripError,
  //  {
   //   postprocess: (payload, dispatch) => {
    return async function (dispatch, getState) {
       // dispatch(findTripResponse(params))
        dispatch(findStopsForTrip({tripId: params.tripId}))
        dispatch(findStopTimesForTrip({tripId: params.tripId}))
        dispatch(findGeometryForTrip({tripId: params.tripId}))
    }
     // }
   // }
 // )
}

// Stops for trip query

export const findStopsForTripResponse = createAction('FIND_STOPS_FOR_TRIP_RESPONSE')
export const findStopsForTripError = createAction('FIND_STOPS_FOR_TRIP_ERROR')

export function findStopsForTrip (params) {
  const locale = "?locale=" + (getItem('userLanguage') || "he");
  return createQueryAction(
    `index/trips/${params.tripId}/stops` + locale,
    findStopsForTripResponse,
    findStopsForTripError,
    {
      rewritePayload: (payload) => {
        return {
          tripId: params.tripId,
          stops: payload
        }
      }
    }
  )
}

// Stop times for trip query

export const findStopTimesForTripResponse = createAction('FIND_STOP_TIMES_FOR_TRIP_RESPONSE')
export const findStopTimesForTripError = createAction('FIND_STOP_TIMES_FOR_TRIP_ERROR')

export function findStopTimesForTrip (params) {
  const locale = "?locale=" + (getItem('userLanguage') || "he");
  return createQueryAction(
    `index/trips/${params.tripId}/stoptimes` + locale,
    findStopTimesForTripResponse,
    findStopTimesForTripError,
    {
      rewritePayload: (payload) => {
        return {
          tripId: params.tripId,
          stopTimes: payload
        }
      },
      noThrottle: true
    }
  )
}

// Geometry for trip query

export const findGeometryForTripResponse = createAction('FIND_GEOMETRY_FOR_TRIP_RESPONSE')
export const findGeometryForTripError = createAction('FIND_GEOMETRY_FOR_TRIP_ERROR')

export function findGeometryForTrip (params) {
  const { tripId } = params
  return createQueryAction(
    `index/trips/${tripId}/geometry`,
    findGeometryForTripResponse,
    findGeometryForTripError,
    {
      rewritePayload: (payload) => ({ tripId, geometry: payload })
    }
  )
}

const findStopTimesForStopResponse = createAction('FIND_STOP_TIMES_FOR_STOP_RESPONSE')
const findStopTimesForStopError = createAction('FIND_STOP_TIMES_FOR_STOP_ERROR')

/**
 * Stop times for stop query (used in stop viewer).
 */
export function findStopTimesForStop (params) {
  return function (dispatch, getState) {
    let { stopId,agencyFilter, ...otherParams } = params
    // If other params not provided, fall back on defaults from stop viewer config.
    const queryParams = { ...getStopViewerConfig(getState().otp), ...otherParams }
    // If no start time is provided, pass in the current time. Note: this is not
    // a required param by the back end, but if a value is not provided, the
    // time defaults to the server's time, which can make it difficult to test
    // scenarios when you may want to use a different date/time for your local
    // testing environment.
    if (!queryParams.startTime) {
      const nowInSeconds = Math.floor((new Date()).getTime() / 1000)
      queryParams.startTime = nowInSeconds
    }
    const agencyFilterQuery = agencyFilter ? '&agencyFilter=' + agencyFilter : '' ;
    const locale = "&locale=" + (getItem('userLanguage') || "he");
    dispatch(createQueryAction(
      `index/stops/${stopId}/stoptimes?${qs.stringify(queryParams)}${agencyFilterQuery}${locale}`,
      findStopTimesForStopResponse,
      findStopTimesForStopError,
      {
        rewritePayload: (stopTimes) => {
          return {
            stopId,
            stopTimes
          }
        },
        noThrottle: true
      }
    ))
  }
}

//find stations
const findStationsResponse = createAction('FIND_STATIONS_RESPONSE')
const findStationsError = createAction('FIND_STATIONS_ERROR')

export function findStations (query, locale) {
  return createQueryAction(
      'stopSearch?query=' + query +'&locale=' + locale,
      findStationsResponse,
      findStationsError,
      {
        ignoreEmptyArray:true,
          rewritePayload: (payload) => {
            return payload.map(stop => {
              return {
              id:"1:" + stop.stop_id,
              lat:stop.latitude,
              lon:stop.longitude,
              formatted_address:stop.address,
              code:stop.stop_code,
              name:stop.stop_name,
              location_type:stop.location_type,
              }

            })
          }
      },
      "app"
  )
}
//

// Routes lookup query

const findRoutesResponse = createAction('FIND_ROUTES_RESPONSE')
const findRoutesError = createAction('FIND_ROUTES_ERROR')
export const setLineNumber = createAction('SET_LINE_NUMBER');


let currentRouteSearchOptions = null;
export function findRoutes (params, locale, agencyFilter = null ) {
  if(currentRouteSearchOptions){
    currentRouteSearchOptions.aborted = true;
  }
  currentRouteSearchOptions =   {
      noThrottle: true,
      serviceId: 'routes',
      ignoreEmptyArray:true
     /* rewritePayload: (payload) => {
        const routes = {}
        payload.forEach(rte => { routes[rte.id] = rte })
        return routes
      }*/
    }
  return createQueryAction(
    'index/patterns/byshortname/' + params +'?locale=' + locale + (agencyFilter ? '&agencyFilter=' + agencyFilter  : '' ),
    findRoutesResponse,
    findRoutesError,
    currentRouteSearchOptions
  )
}

// export function findRoutes (params) {
//   const query = `
// {
//   routes {
//     id: gtfsId
//     color
//     longName
//     shortName
//     mode
//     type
//     desc
//     bikesAllowed
//     sortOrder
//     textColor
//     url
//     agency {
//       id: gtfsId
//       name
//       url
//     }
//   }
// }
//   `
//   return createGraphQLQueryAction(
//     query,
//     {},
//     findRoutesResponse,
//     findRoutesError,
//     {
//       serviceId: 'routes',
//       rewritePayload: (payload) => {
//         const routes = {}
//         payload.data.routes.forEach(rte => { routes[rte.id] = rte })
//         return routes
//       }
//     }
//   )
// }

// Patterns for Route lookup query
// TODO: replace with GraphQL query for route => patterns => geometry
const findPatternsForRouteResponse = createAction('FIND_PATTERNS_FOR_ROUTE_RESPONSE')
const findPatternsForRouteError = createAction('FIND_PATTERNS_FOR_ROUTE_ERROR')

// Single Route lookup query

export const findRouteResponse = createAction('FIND_ROUTE_RESPONSE')
export const findRouteError = createAction('FIND_ROUTE_ERROR')

export function findRoute(params, locale = (getItem('userLanguage') || "he"), agencyFilter = null) {
  return createQueryAction(
    `index/routes/${params.routeId}?locale=${locale}${agencyFilter ? '&agencyFilter=' + agencyFilter  : ''}`,
    findRouteResponse,
    findRouteError,
    {
      postprocess: (payload, dispatch) => {
        // load patterns
        if(params.routing){
          dispatch(findRoutes(payload.shortName, locale, agencyFilter))
          dispatch(setViewedRoute({ routeId: params.routeId, pattern: params.pattern }))
        }
          dispatch(findPatternsForRoute({ patternId: params.pattern,routeId:params.routeId }, locale, agencyFilter))
      },
      noThrottle: true
    }
  )
}

export const findRouteAlertsResponse = createAction('FIND_ROUTE_ALERTS_RESPONSE')
export const findRouteAlertsError = createAction('FIND_ROUTE_ALERTS_ERROR')

export function findRouteAlerts(params, locale = (getItem('userLanguage') || "he"), agencyFilter = null) {
  return createQueryAction(
    `patch/routeAlerts/${params.routeId}?locale=${locale}`,
    findRouteAlertsResponse,
    findRouteAlertsError,{
      noThrottle: true,
      rewritePayload: (payload) => {
        if(payload && payload.alertPatches && payload.alertPatches.length && !payload.alertPatches[0].route){
          payload.alertPatches[0].route = params.routeId;
        }
        return payload;
      },
    }
  )
}

export const findStopAlertsResponse = createAction('FIND_STOP_ALERTS_RESPONSE')
export const findStopAlertsError = createAction('FIND_STOP_ALERTS_ERROR')

export function findStopAlerts(params, locale = (getItem('userLanguage') || "he"), agencyFilter = null) {
  return createQueryAction(
    `patch/stopAlerts/${params.stopId}?locale=${locale}`,
    findStopAlertsResponse,
    findStopAlertsError,{
      noThrottle: true,
    }
  )
}



export function findPatternsForRoute(params, locale, agencyFilter = null) {
  return createQueryAction(
    `index/patterns/${params.patternId}?locale=${locale}${agencyFilter ? '&agencyFilter=' + agencyFilter  : ''}`,
    findPatternsForRouteResponse,
    findPatternsForRouteError,
    {
      noThrottle:true,
      rewritePayload: (payload) => {
        // convert pattern array to ID-mapped object
        if(payload && !payload.length){
            payload = [payload];
        }
        const patterns = {}
        payload.forEach(ptn => { patterns[ptn.id] = ptn })


        return {
          routeId: params.routeId,
          patterns
        }
      },
      postprocess: (payload, dispatch) => {
        // load geometry for each pattern

        dispatch(findRouteAlerts(params,locale,agencyFilter))

        if(payload && !payload.length){
          payload = [payload];
      }
        let foundPattern = false;
        payload.forEach(ptn => {
          if(ptn.id == params.patternId){
            foundPattern = true;
          }
          dispatch(findGeometryForPattern({
            routeId: params.routeId,
            patternId: ptn.id
          }))
        })
        if(!foundPattern){
          dispatch(setViewedRoute({ routeId: params.routeId, pattern:payload[0].id}))
        }
      }
    }
  )
}

// Geometry for Pattern lookup query

const findGeometryForPatternResponse = createAction('FIND_GEOMETRY_FOR_PATTERN_RESPONSE')
const findGeometryForPatternError = createAction('FIND_GEOMETRY_FOR_PATTERN_ERROR')

export function findGeometryForPattern (params) {
  return createQueryAction(
    `index/patterns/${params.patternId}/geometry`,
    findGeometryForPatternResponse,
    findGeometryForPatternError,
    {
      noThrottle:true,
      rewritePayload: (payload) => {
        return {
          routeId: params.routeId,
          patternId: params.patternId,
          geometry: payload
        }
      }
    }
  )
}



// export function findRoute (params) {
//   const query = `
//   query routeQuery($routeId: [String]) {
//     routes (ids: $routeId) {
//       id: gtfsId
//       patterns {
//         id: semanticHash
//         directionId
//         headsign
//         name
//         semanticHash
//         geometry {
//           lat
//           lon
//         }
//       }
//     }
//   }
//   `
//   return createGraphQLQueryAction(
//     query,
//     { routeId: params.routeId },
//     findPatternsForRouteResponse,
//     findPatternsForRouteError,
//     {
//       rewritePayload: (payload) => {
//         // convert pattern array to ID-mapped object
//         const patterns = {}
//         payload.data.routes[0].patterns.forEach(ptn => {
//           patterns[ptn.id] = {
//             routeId: params.routeId,
//             patternId: ptn.id,
//             geometry: ptn.geometry
//           }
//         })
//
//         return {
//           routeId: params.routeId,
//           patterns
//         }
//       }
//     }
//   )
// }


// Nearby Stops Query

const receivedNearbyStopsResponse = createAction('NEARBY_STOPS_RESPONSE')
const receivedNearbyStopsError = createAction('NEARBY_STOPS_ERROR')

export function findNearbyStops (params) {
  return createQueryAction(
    `index/stops?${qs.stringify({locale:(getItem('userLanguage') || "he"),radius: 1000, ...params})}`,
    receivedNearbyStopsResponse,
    receivedNearbyStopsError,
    {
      serviceId: 'stops',
      rewritePayload: stops => {
        if (stops) {
          // Sort the stops by proximity
          stops.forEach(stop => {
            stop.distance = haversine(
              { latitude: params.lat, longitude: params.lon },
              { latitude: stop.lat, longitude: stop.lon }
            )
          })
          stops.sort((a, b) => { return a.distance - b.distance })
          if (params.max && stops.length > params.max) stops = stops.slice(0, params.max)
        }
        return {stops}
      },
      // retrieve routes for each stop
      postprocess: (stops, dispatch, getState) => {
        if (params.max && stops.length > params.max) stops = stops.slice(0, params.max)
        stops.forEach(stop => dispatch(findRoutesAtStop(stop.id)))
      }
    }
  )
}

// Routes at Stop query

const receivedRoutesAtStopResponse = createAction('ROUTES_AT_STOP_RESPONSE')
const receivedRoutesAtStopError = createAction('ROUTES_AT_STOP_ERROR')

export function findRoutesAtStop (stopId) {
  return createQueryAction(
    `index/stops/${stopId}/routes`,
    receivedRoutesAtStopResponse,
    receivedRoutesAtStopError,
    {
      serviceId: 'stops/routes',
      rewritePayload: routes => ({ stopId, routes }),
      noThrottle: true
    }
  )
}

// Stops within Bounding Box Query

const receivedStopsWithinBBoxResponse = createAction('STOPS_WITHIN_BBOX_RESPONSE')
const receivedStopsWithinBBoxError = createAction('STOPS_WITHIN_BBOX_ERROR')

export function findStopsWithinBBox (params) {
  return createQueryAction(
    `index/stops?${qs.stringify(params)}`,
    receivedStopsWithinBBoxResponse,
    receivedStopsWithinBBoxError,
    {
      serviceId: 'stops',
      rewritePayload: stops => ({stops})
    }
  )
}

export const clearStops = createAction('CLEAR_STOPS_OVERLAY')

const throttledUrls = {}

function now () {
  return (new Date()).getTime()
}

const TEN_SECONDS = 10000

// automatically clear throttled urls older than 10 seconds
window.setInterval(() => {
  Object.keys(throttledUrls).forEach(key => {
    if (throttledUrls[key] < now() - TEN_SECONDS) {
      delete throttledUrls[key]
    }
  })
}, 1000)

/**
 * Generic helper for constructing API queries. Automatically throttles queries
 * to url to no more than once per 10 seconds.
 *
 * @param {string} endpoint - The API endpoint path (does not include
 *   '../otp/routers/router_id/')
 * @param {Function} responseAction - Action to dispatch on a successful API
 *   response. Accepts payload object parameter.
 * @param {Function} errorAction - Function to invoke on API error response.
 *   Accepts error object parameter.
 * @param {Options} options - Any of the following optional settings:
 *   - rewritePayload: Function to be invoked to modify payload before being
 *       passed to responseAction. Accepts and returns payload object.
 *   - postprocess: Function to be invoked after responseAction is invoked.
 *       Accepts payload, dispatch, getState parameters.
 *   - serviceId: identifier for TransitIndex service used in
 *       alternateTransitIndex configuration.
 *   - fetchOptions: fetch options (e.g., method, body, headers).
 */

function createQueryAction (endpoint, responseAction, errorAction, options = {}, service = null) {
  return async function (dispatch, getState) {
    const otpState = getState().otp
    let url
    if (options.serviceId && otpState.config.alternateTransitIndex &&
      otpState.config.alternateTransitIndex.services.includes(options.serviceId)
    ) {
      console.log('Using alt service for ' + options.serviceId)
      url = otpState.config.alternateTransitIndex.apiRoot + endpoint
    } else {
      const api = service ? otpState.config[service] : otpState.config.api
      url = `${api.host}${api.port ? ':' + api.port : ''}${api.path}/${endpoint}`
    }

    if (!options.noThrottle) {
      // don't make a request to a URL that has already seen the same request
      // within the last 10 seconds
      const throttleKey = options.fetchOptions
        ? `${url}-${hash(options.fetchOptions)}`
        : url
      if (throttledUrls[throttleKey] && throttledUrls[throttleKey] > now() - TEN_SECONDS) {
        // URL already had a request within last 10 seconds, warn and exit
        console.warn(`Request throttled for url: ${url}`)
        return
      } else {
        throttledUrls[throttleKey] = now()
      }
    }
    let payload
    try {
      const response = await fetch(url, options.fetchOptions)
      if(options.aborted){
       // console.log("fetch aborted");
        return;
      }
      if (response.status >= 400) {
        const error = new Error('Received error from server')
        error.response = response
        throw error
      }
      payload = await response.json()
      if(options.ignoreEmptyArray && (!payload || !payload.length)){
        return; // don't update the state if no results
      }
    } catch (err) {
      return dispatch(errorAction(err))
    }

    if (typeof options.rewritePayload === 'function') {
      dispatch(responseAction(options.rewritePayload(payload)))
    } else {
      dispatch(responseAction(payload))
    }

    if (typeof options.postprocess === 'function') {
      options.postprocess(payload, dispatch, getState)
    }
  }
}

// TODO: Determine how we might be able to use GraphQL with the alternative
// transit index. Currently this is not easily possible because the alternative
// transit index does not have support for GraphQL and handling both Rest and
// GraphQL queries could introduce potential difficulties for maintainers.
// function createGraphQLQueryAction (query, variables, responseAction, errorAction, options) {
//   const endpoint = `index/graphql`
//   const fetchOptions = {
//     method: 'POST',
//     body: JSON.stringify({ query, variables }),
//     headers: { 'Content-Type': 'application/json' }
//   }
//   return createQueryAction(
//     endpoint,
//     responseAction,
//     errorAction,
//     { ...options, fetchOptions }
//   )
// }

/**
 * Update the browser/URL history with new parameters
 * NOTE: This has not been tested for profile-based journeys.
 */
export function setUrlSearch (params , replaceCurrent = false, resetHistory = false) {
  return function (dispatch, getState) {
    const agencyFilter = getState().otp.currentQuery.agencyFilter ? 'agencyFilter=' + getState().otp.currentQuery.agencyFilter : '' ;

    let base =  checkSameSearchTab(getState().router.location.pathname) && !resetHistory ?  getState().router.location.pathname : '/';
    if(!params){
      params = getUrlParams() || {};
    }

    let queryS =  qs.stringify(params);
    if(!queryS.includes(agencyFilter))
      queryS = queryS +  agencyFilter
    let path = `${base}?${queryS}`
    let ulrWindow = window.location.href

    if(!ulrWindow.includes('agencyFilter'))
    {
      if (replaceCurrent)
         dispatch(replace(path))
     // else 
       // dispatch(push(path))
    }
  }
}

/**
 * Update the OTP Query parameters in the URL and ensure that the active search
 * is set correctly. Leaves any other existing URL parameters (e.g., UI) unchanged.
 */
export function updateOtpUrlParams (otpState, searchId) {
  const otpParams = getRoutingParams(otpState, false)
  return function (dispatch, getState) {
    const params = {}
    // Get all OTP-specific params, which will be retained unchanged in the URL
    const urlParams = getUrlParams()
    Object.keys(urlParams)
      .filter(key => key.indexOf('_') !== -1)
      .forEach(key => { params[key] = urlParams[key] })
    params.ui_activeSearch = searchId
    // Assumes this is a new search and the active itinerary should be reset.
    params.ui_activeItinerary = 0
    // Merge in the provided OTP params and update the URL
    dispatch(setUrlSearch(Object.assign(params, otpParams)))
  }
}

export function checkSameSearchTab(routerHistoryPath) {
  let windowPath = window.location.pathname;
  let searchType ='plan';

  switch (windowPath) {
    case "/searchRoute":
    case "/route":
      searchType = 'route';
      break;
    case "/searchStations":
      searchType = 'stop';
      break;
    default:
      break;
  }

  return routerHistoryPath.includes(searchType)
}
