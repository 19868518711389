import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import React, {lazy} from "react";

function RouteWeb() {
  const OptView = lazy(() => import('./lib/components/app/opt-view'))
  const TransitPrice = lazy(() => import('./lib/components/transit-prices/welcome'))
  const SelectTrip = lazy(() => import('./lib/components/transit-prices/select-trip'))
  const SearchResult = lazy(() => import('./lib/components/transit-prices/search-result'))
  const PricesResult = lazy(() => import('./lib/components/transit-prices/prices-result'))

  //const isLocalhost = window.location.hostname.match(/localhost|prices|127\.0\.0\.1/g);
  const isPriceCalculator = window.location.hostname.match(/prices/g);
  const RootComponent = isPriceCalculator ? TransitPrice : OptView;
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<Navigate to='/plan' replace />} />
        {["/plan", "/searchRoute", "searchStations", "/route/:id", "/stop/:id"].map((path,index) => (
          <Route
            key={index}
            path={path}
            element={<RootComponent />}
          />
        ))}
        <Route exact path="/transit_prices" element={<TransitPrice />}/>
        <Route exact path="/transit_prices/select-trip" element={<SelectTrip />}/>
        <Route exact path="/transit_prices/search-result" element={<SearchResult />}/>
        <Route exact path="/transit_prices/prices-result" element={<PricesResult/>} embeded="ravpass"/>
      </Routes>
    </BrowserRouter>
  )
}

export default RouteWeb;