import bowser from "bowser";
import { MainPanelContent,rewriteHeadsign } from '../../../actions/ui'
import { summarizeQuery } from './query'
import { getActiveSearch } from './state'

const DEFAULT_TITLE = "הופאון רב-פס | אוטובוס קרוב";
const DEFAULT_DESCRIPTION = "תכנון מסלול ומחשבון תעריפי דרך שווה לתיקוף מהנייד עם רב-פס או עם הרב-קו בתחבורה הציבורית"
export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export function isCrawler() {
  return /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent);
}

/**
 * Returns true if the user is using a [redacted] browser
 */
export function isIE() {
  return bowser.name === "Internet Explorer";
}

/**
 * Enables scrolling for a specified selector, while disabling scrolling for all
 * other targets. This is adapted from https://stackoverflow.com/a/41601290/915811
 * and intended to fix issues with iOS elastic scrolling, e.g.,
 * https://github.com/conveyal/trimet-mod-otp/issues/92.
 */
export function enableScrollForSelector(selector) {
  const overlay = document.querySelector(selector);
  let clientY = null; // remember Y position on touch start

  function isOverlayTotallyScrolled() {
    // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#Problems_and_solutions
    return overlay.scrollHeight - overlay.scrollTop <= overlay.clientHeight;
  }

  function disableRubberBand(event) {
    const clientYDelta = event.targetTouches[0].clientY - clientY;

    if (overlay.scrollTop === 0 && clientYDelta > 0) {
      // element is at the top of its scroll
      event.preventDefault();
    }

    if (isOverlayTotallyScrolled() && clientYDelta < 0) {
      // element is at the top of its scroll
      event.preventDefault();
    }
  }

  overlay.addEventListener(
    "touchstart",
    function(event) {
      if (event.targetTouches.length === 1) {
        // detect single touch
        clientY = event.targetTouches[0].clientY;
      }
    },
    false
  );

  overlay.addEventListener(
    "touchmove",
    function(event) {
      if (event.targetTouches.length === 1) {
        // detect single touch
        disableRubberBand(event);
      }
    },
    false
  );
}

function getTitleForRoute(shortName,agency,headsign){
  return `לוח זמנים, תחנות ומפות לקו ${shortName} של ${agency} ל${headsign}`
}

export function getTitle (state) {
  // Override title can optionally be provided in config.yml
  const { config, ui, user } = state.otp
  let title = config.title || DEFAULT_TITLE
  const { mainPanelContent, viewedRoute, viewedStop,viewedTrip } = ui
  switch (mainPanelContent) {
    case MainPanelContent.ROUTE_VIEWER:
      title = " - הופאון אוטובוס קרוב";
      if (viewedRoute && viewedRoute.routeId){
        
        if(viewedRoute.route){
        let shortName = viewedRoute.route.shortName
        let headsign = viewedRoute.headsign;
        let agency = viewedRoute.route.agency?.name || viewedRoute.route.agencyName;
        title = getTitleForRoute(shortName,agency,headsign) + title;
  

        }else{

          let indexedRoute = state.otp.transitIndex && state.otp.transitIndex.routes && state.otp.transitIndex.routes[viewedRoute.routeId];
          let pattern = indexedRoute.patterns && viewedRoute.pattern && indexedRoute.patterns[viewedRoute.pattern];
          if(pattern){
            let shortName = indexedRoute.shortName
            let headsign = pattern.headsign && rewriteHeadsign(pattern.headsign)
            let agency = indexedRoute.agency.name;
            title = getTitleForRoute(shortName,agency,headsign) + title;
          }else{
            title = 'הופאון אוטובוס קרוב | לוח זמנים, תחנות ומפות'
          }
        }
      }else{
          title = 'הופאון אוטובוס קרוב | לוח זמנים, תחנות ומפות'
      }
      break
    case MainPanelContent.STOP_VIEWER:

      if (viewedStop && viewedStop.stopId && state.otp.transitIndex.stops[viewedStop.stopId]){
        const stop = state.otp.transitIndex.stops[viewedStop.stopId]
        title = `לוח זמנים לתחנת ${stop.name} - ${stop.formatted_address} - הופאון אוטובוס קרוב`;
      }else{
        title = "לוח זמנים לתחבורה ציבורית בישראל - הופאון אוטובוס קרוב";
      }
      break
    default:
      const activeSearch = getActiveSearch(state.otp)
      if (activeSearch) {
        if(viewedTrip && viewedTrip.leg){
            let shortName = viewedTrip.leg.routeShortName
            let headsign = rewriteHeadsign(viewedTrip.leg.headsign)
            let agency = viewedTrip.leg.agencyName
            title = getTitleForRoute(shortName,agency,headsign) + title;
        }else{
          title = `${summarizeQuery(activeSearch.query, user.locations)}` + " - הופאון אוטובוס קרוב"
        }
      }else if(state.otp.transitIndex && state.otp.transitIndex.routesArray && state.otp.transitIndex.routesArray){
          let route = state.otp.transitIndex.routesArray[0];
          if(state.otp.transitIndex.line){
            let shortName = route.route.shortName;
            let agency = route.route.agency.name;
            let headsign = rewriteHeadsign(route.headsign);
            title = getTitleForRoute(shortName,agency,headsign) + " - הופאון אוטובוס קרוב"
          }else{
            title = `מחירים ומסלולי קווי האוטובוס והרכבות של ` + route.route.agency.name + title;
          }
      }else if(state.router?.location?.query?.to){

        title = `תכנון מסלול בתחבורה ציבורית אל ` + decodeURI(state.router.location.query.to) + ` - הופאון אוטובוס קרוב`

      }else{

       // title = "";
      }
      break
  }
  // if (printView) title += ' | Print'
  return title
}

function rewriteLongName(longName){
  let result = ""
  if(longName && longName.includes('<->')){
     result = " היוצא מ" + longName.replace('<->',' ומגיע אל ');
     result = result.substr(0,result.length-3)
  }
  return result;
  
}

export function getDescription(state){
  const {  ui} = state.otp
  const { mainPanelContent, viewedRoute, viewedStop} = ui
  let description = DEFAULT_DESCRIPTION;
  switch (mainPanelContent) {

    case MainPanelContent.ROUTE_VIEWER:
      if (viewedRoute && viewedRoute.routeId && viewedRoute.route){
        let shortName = viewedRoute.route.shortName
        description = "כל המידע על קו " + shortName + rewriteLongName(viewedRoute.route.longName);

      }

    break;
    case MainPanelContent.STOP_VIEWER:
      if (viewedStop && viewedStop.stopId && state.otp.transitIndex.stops[viewedStop.stopId]){
        const stop = state.otp.transitIndex.stops[viewedStop.stopId]
        description = `דרכי הגעה אל תחנה ${stop.code} ${stop.name} - ${stop.formatted_address}, מסלולי הקווים העוצרים בתחנה ולוחות זמנים`
        

      }

    break;
    default:
      break;


  }

  return DEFAULT_DESCRIPTION;
}
