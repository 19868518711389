import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import transEn from './lib/translations/translations-en';
import transHe from './lib/translations/translations-he';
import transAr from './lib/translations/translations-ar';
import transRu from './lib/translations/translations-ru';
import transDe from './lib/translations/translations-de';
import transCs from './lib/translations/translations-cs';
import 'moment/locale/he';
import 'moment/locale/ru';
import 'moment/locale/ar';
import 'moment/locale/de';
import 'moment/locale/cs';
import {getItem} from './lib/@opentripplanner/core-utils/src/storage'
import moment from "moment";
import configFile from './lib/config'


i18n
  .use(initReactI18next)
  .init({
    interpolation: {escapeValue: false},  // React already does escaping
    lng: getItem('userLanguage') || configFile.language || 'he',                              // language to use
    fallbackLng: ['he', 'en', 'ar', 'ru', 'de','cs'],
    resources: {
      en: {
        translation: transEn
      },
      he: {
        translation: transHe
      },
      ar: {
        translation: transAr
      },
      ru: {
        translation: transRu
      },
      de: {
        translation: transDe
      },
      cs: {
        translation: transCs
      }
    },
  });

moment.locale(getItem('userLanguage') || 'he');

i18n.on('languageChanged', function (lng) {
  moment.locale(lng);
});
export default i18n;